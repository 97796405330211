import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutofocusDirective } from '@flipto/shared/src/lib/directives/accessibility/autofocus.directive';
import { BackgroundImageDirective } from '@flipto/shared/src/lib/directives/image/background-image.directive';
import { FitTextDirective } from '@flipto/shared/src/lib/directives/text/fit-text.directive';
import { DelayedClassDirective } from '@flipto/shared/src/lib/directives/effects/delayed-class.directive';

@NgModule({
    declarations: [
        AutofocusDirective,
        BackgroundImageDirective,
        FitTextDirective,
        DelayedClassDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AutofocusDirective,
        BackgroundImageDirective,
        FitTextDirective,
        DelayedClassDirective
    ]
})
export class SharedDirectivesModule {
}
