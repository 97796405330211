import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialLettersPipe } from './initial-letters.pipe';
import { JoinWithPipe } from './join-with.pipe';
import { FullNamePipe } from './full-name.pipe';
import { CssUrlPipe } from './css-url.pipe';
import { ClonePipe } from './clone.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeStylePipe } from './safe-style.pipe';
import { FilterPipe } from './filter.pipe';
import { ParseValuePipe } from './parse-value.pipe';
import { CnameUrlPipe } from './cnameUrl.pipe';
import { PercentagePipe } from './percentage.pipe';
import { SharePipe } from './share.pipe';
import { UserLastActivityTypePipe } from './active-type.pipe';
import { IncentiveTypePipe } from './incentive-type.pipe';
import { LanguagePreviewPipe } from './language-preview.pipe';
import { GetBooleanPipe } from './get-boolean.pipe';
import { DateDifferencePipe } from './date-difference.pipe';
import { IsDateInListPipe } from './is-date-in-list.pipe';
import { IsDateInRangePipe } from './is-date-in-range.pipe';
import { IsDateInPastPipe } from './is-date-in-past.pipe';
import { NgbDateToDatePipe } from './ngb-date-to-date.pipe';
import { DateToNgbDatePipe } from './date-to-ngb-date.pipe';
import { FormatPipe } from './format.pipe';

const PIPES = [
    InitialLettersPipe,
    JoinWithPipe,
    FullNamePipe,
    CssUrlPipe,
    ClonePipe,
    OrdinalPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    FilterPipe,
    ParseValuePipe,
    CnameUrlPipe,
    PercentagePipe,
    SharePipe,
    UserLastActivityTypePipe,
    IncentiveTypePipe,
    LanguagePreviewPipe,
    GetBooleanPipe,
    DateDifferencePipe,
    IsDateInListPipe,
    IsDateInRangePipe,
    IsDateInPastPipe,
    NgbDateToDatePipe,
    DateToNgbDatePipe,
    FormatPipe,
];


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [...PIPES],
    exports: [...PIPES]
})
export class PipesModule {

}
