import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePathPipe } from '../../pipes/file-path.pipe';
import { GeopatternBackgroundDirective } from '../../directives/geopattern/geopattern-background.directive';
import { GeopatternBackgroundPipe } from '../../pipes/geopattern.pipe';
import { CheckLicenseDirective } from '../../directives/check-license.directive';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { FliptoToggleComponent } from '../../components/flipto-toggle/flipto-toggle.component';
import { AzureImageUrlPipe } from '../../pipes/azure-image-url.pipe';
import { CreateAccountComponent } from '../account-management/components/create-account/create-account.component';
import { OnOffTogglePipe } from 'account-hybrid/common/pipes/on-off-toggle.pipe';
import { FaceHighlightersDirective } from '../../directives/face-highlighters.directive';
import { LanguageDataPipe } from 'account-hybrid/common/pipes/language-data.pipe';


@NgModule({
    declarations: [
        FilePathPipe,
        AzureImageUrlPipe,
        GeopatternBackgroundDirective,
        GeopatternBackgroundPipe,
        OnOffTogglePipe,
        CheckLicenseDirective,
        FaceHighlightersDirective,
        FliptoToggleComponent,
        LanguageDataPipe
    ],
    imports: [
        CommonModule,
        AccountSharedModule
    ],
    exports: [
        FilePathPipe,
        AzureImageUrlPipe,
        OnOffTogglePipe,
        GeopatternBackgroundDirective,
        GeopatternBackgroundPipe,
        CheckLicenseDirective,
        FliptoToggleComponent,
        FaceHighlightersDirective,
        LanguageDataPipe
    ]
})
export class CommonDeclarationsModule {
}
